import EditProfileForm from "./inners/EditProfileForm/EditProfileForm";
import ProfileDetails from "./inners/ProfileDetails/ProfileDetails";
import ProfilePayments from "./inners/ProfilePayments/ProfilePayments";
import ProfileRecentNotes from "./inners/ProfileRecentNotes/ProfileRecentNotes";
import ProfileTabs from "./inners/ProfileTabs/ProfileTabs";
import ReferFriendForm from "./inners/ReferFriendForm/ReferFriendForm";
import styles from "./ProfilePage.module.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useIsAuthenticated, useUserRole } from "@podcasts/hooks";

export function ProfilePage() {
  const location = useLocation();
  const { isStudent } = useUserRole();

  const isAuthenticated = useIsAuthenticated();

  const showTabs = location.pathname !== "/profile/edit";

  return isAuthenticated ? (
    <div className={`${styles["profile-page"]}`}>
      <div className="container custom-container">
        <ProfileDetails />
        {showTabs && <ProfileTabs />}
        <Routes>
          <Route path={`edit`} element={<EditProfileForm />} />
          <Route path={`/payments`} element={<ProfilePayments />} />
          <Route path={`/notes`} element={<ProfileRecentNotes />} />
          <Route path={`/refer`} element={<ReferFriendForm />} />
          <Route path="*" element={<Navigate to={`${isStudent ? "/profile/payments" : "/profile/notes"}`} replace />} />
        </Routes>
      </div>
    </div>
  ) : null;
}

export default ProfilePage;
