import styles from "./ProfileTabs.module.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUserRole } from "@podcasts/hooks";

const tabs = [
  {
    name: "Recent Notes",
    path: "/profile/notes",
    icon: "icon-notes",
  },
  {
    name: "Refer a friend",
    path: "/profile/refer",
    icon: "icon-user-plus",
  },
  {
    name: "Saved Podcasts",
    path: "/podcasts/saved-podcasts",
    icon: "icon-empty-star",
  },
];

export function ProfileTabs() {
  const { pathname } = useLocation();
  const { isStudent } = useUserRole();

  if (isStudent && tabs.length === 3) {
    tabs.unshift( {
      name: "Your Payments",
      path: "/profile/payments",
      icon: "icon-payment-empty",
    });
  }

  return (
    <div className={`${styles["profile-tabs"]}`}>
      <ul>
        {tabs.map((t: any, i) => (
          <li
            key={i}
            className={`${t.path === pathname ? styles["active"] : ""} `}
          >
            <div className={styles["link-box"]}>
              <i className={t.icon}></i>
              <Link to={t.path}>{t.name}</Link>
            </div>
            <span className={`${styles["chevron-mobile"]}`}>
              <i className="icon-chevron-right"></i>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProfileTabs;
