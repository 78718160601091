import React, {useEffect, useState} from "react";
import styles from "../RegisterPage.module.scss";
import { InlineWidget } from "react-calendly";
import Button from "../../../components/Button/Button";
import moment from "moment";
import { CheckBox } from "../../../components/FormItems/CheckBox/CheckBox";

export function Schedule({ user, onNext, onPrev }: any) {
  const [calendlyUrl, setCalendlyUrl] = useState("https://calendly.com/podcastfellow/interview");

  useEffect(() => {
    if (user) {
      if (moment().diff(user.dob, 'years') >= 32) {
        setCalendlyUrl("https://calendly.com/dhaas-1-1-learning/15min");
      }
    }
  }, [user]);

  return (
    <div className={styles["step"]}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className={styles["step-number"]}>
              <span>4</span>
            </div>
            <h2 className={`${styles["stepTitle"]}`}>
              Schedule an intake interview to{" "}
              <span className="orange-yellow-color">
                complete your application.
              </span>
            </h2>
          </div>
          <div className="col-lg-6">
            <p>Please select a time for your intake interview.  An interview is required in order to be accepted to the Podcast Fellowship</p>
            <InlineWidget url={calendlyUrl} />
            <div className="mt-5 row">
              <div className="col-sm">
                <CheckBox
                    name=""
                    value={false}
                    label="I have selected a timeslot for my intake interview from the calendar above."
                    checked={false}
                />
              </div>
            </div>
            <div className={`${styles["formRow"]} row`}>
              <div
                className={`${styles["prev-step"]} d-flex align-items-center col-lg`}
              >
                <span onClick={onPrev}>
                    Previous step
                </span>
              </div>
              <div className="col-lg mt-0">
                <Button
                  name="Submit"
                  onClick={() => {
                    onNext();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
